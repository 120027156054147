import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import queryString from "query-string";

// seo
import Seo from "../../../components/Seo";

// styles
import * as styles from "./twin-works.module.css";
import { Link } from "gatsby";
import { useTwinWorkAppQuery } from "../../../hooks/useTwinWorkAppQuery";

const TwinWorksPage = props => {
  const node = useTwinWorkAppQuery();
  const planPrice = node[0].planPrice;

  const { clientId } = queryString.parse(props.location.search);

  const goToTwinPlan = () => {
    if (window.twinApp) {
      const ctData = JSON.stringify({
        action: "cleverTapEvent",
        arguments: {
          event: "TwinWorks_BottomSection_Cta1",
          payload: { action: "clicked on enroll now cta button" },
        },
      });
      window.twinApp.postMessage(ctData);
      const data = JSON.stringify({ action: "dx_home_twin_plan" });
      window.twinApp.postMessage(data);
    } else {
      console.log("clicked on CTA");
    }
  };

  return (
    <div className={`${styles.twinWork_sec}`}>
      <Seo
        title="Reverse Diabetes, Obesity, PCOS & More"
        description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
      />
      <div className="mt-4 px-4">
        <div className={`row  ${styles.reverseCont}`}>
          <div className={`col-8 ${styles.textCoo}`}>
            <div className={styles.priceText}>Starter pack @ ₹{planPrice}</div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <div>
              <StaticImage
                src="../../../images/twinLogo.svg"
                placeholder="blurred"
                alt="twinLogo"
              />
            </div>
          </div>
          <div className={`col-11 ${styles.textCoo2}`}>
            <div className={styles.reverseContent}>
              Attend webinar to know more about diabetes reversal & its health
              benefits
            </div>
          </div>

          <Link
            to={`/reversal-webinar/?appHeader=no&twinApp=yes&clientId=${clientId}`}
            className={`navLink d-flex`}
            rel="noreferrer"
            onClick={() =>
              window.twinApp &&
              window.twinApp.postMessage(
                JSON.stringify({
                  action: "cleverTapEvent",
                  arguments: {
                    event: "TwinWorks_TopSection_Card1",
                    payload: { action: "clicked on webinar cta button" },
                  },
                })
              )
            }
          >
            <div className="gradientBtnWithArrow pt-2 pb-2 justify-content-center">
              <p className={`pt-2 pb-2 ${styles.bookSlotCta}`}>
                {" "}
                Reserve my seat for webinar{" "}
              </p>
            </div>
          </Link>
          <div
            className={`col-12 d-flex justify-content-center text-center ${styles.ddeve}`}
          >
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <div className="pe-2">
                  <StaticImage
                    src="../../../images/check.svg"
                    placeholder="blurred"
                    alt="check"
                  />
                </div>
                <div className={styles.textConn}>100% MONEY BACK GUARANTEE</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-5 mt-5">
        {/* how twin works */}
        <div className={styles.twinWorkSec_hwTwnWrk}>
          {/* heading */}
          <h2>How Twin works</h2>
          {/* sub-heading */}
          <h3>Introducing Whole Body Digital Twin™</h3>
          {/* image */}
          <StaticImage
            src="../../../images/appDx/Digital_Twin.png"
            alt=""
            placeholder="blurred"
          />
        </div>
        {/* twin precission treatment */}
        <div className={styles.twinWorkSec_twnPrecTreatmnt}>
          <h2>Twin precision treatment</h2>
          <div className={`pt-2 ${styles.text1}`}>Digital Twin + </div>
          <div className={styles.text1}>precision treatment = </div>
          <div className={styles.text1}>diabetes free life!</div>
          <div className={styles.twinWorkSec_twnPrecTreatmnt_para}>
            Your body and its metabolism are unique and deserve precision
            treatment. Twin is a 1 year treatment with 3 phases.
          </div>
          <div
            className={`d-flex align-items-center ${styles.twinWorkSec_twnPrecTreatmnt_subSec}`}
          >
            <p>Normalize</p>
            <div></div>
            <p className="ps-3">Heal</p>
            <div></div>
            <p className="ps-3">Sustain</p>
          </div>
          <div className={styles.twinWorkSec_twnPrecTreatmnt_imgSec}>
            <div className="d-flex align-items-center">
              <div>
                <StaticImage
                  src="../../../images/appDx/precission_nutrition.png"
                  alt=""
                  className={styles.twinWorkSec_twnPrecTreatmnt_imgSecccc}
                  placeholder="blurred"
                  height={160}
                />
              </div>
              <div className={styles.twinWorkSec_twnPrecTreatmnt_imgSecxcdc}>
                Precision nutrition
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <StaticImage
                  src="../../../images/appDx/precission_breathing.png"
                  alt=""
                  className={styles.twinWorkSec_twnPrecTreatmnt_imgSecccc}
                  placeholder="blurred"
                  height={160}
                />
              </div>
              <div className={styles.twinWorkSec_twnPrecTreatmnt_imgSecxcdc}>
                Precision breathing
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <StaticImage
                  src="../../../images/appDx/precission_activity.png"
                  alt=""
                  className={styles.twinWorkSec_twnPrecTreatmnt_imgSecccc}
                  placeholder="blurred"
                  height={160}
                />
              </div>
              <div className={styles.twinWorkSec_twnPrecTreatmnt_imgSecxcdc}>
                Precision activity
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <StaticImage
                  src="../../../images/appDx/precission_sleep.png"
                  alt=""
                  placeholder="blurred"
                  className={styles.twinWorkSec_twnPrecTreatmnt_imgSecccc}
                  height={160}
                />
              </div>
              <div className={styles.twinWorkSec_twnPrecTreatmnt_imgSecxcdc}>
                Precision sleep
              </div>
            </div>
          </div>
        </div>
        {/* unique with twin treatment */}
        <div className={styles.twinWorkSec_unqTwnTreatmnt}>
          <h2 className={styles.twinWorkSec_unqTwnTreatmnt_hdng}>
            What’s unique with the Twin treatment
          </h2>
          <div className={styles.twinWorkSec_unqTwnTreatmnt_contnt}>
            <div className="d-flex align-items-center">
              <div className="align-self-start">
                <StaticImage
                  src="../../../images/appDx/health_coach.png"
                  alt=""
                  placeholder="blurred"
                  width={100}
                  height={100}
                />
              </div>
              <div className={styles.twinWorkSec_unqTwnTreatmnt_contntPrt}>
                <h3>Dedicated personal health coach</h3>
                <p>
                  A dedicated professional health coach to support your reversal
                  every step of the way
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center pt-4 me-4">
              <div className="align-self-start">
                <StaticImage
                  src="../../../images/appDx/sensor.png"
                  alt=""
                  placeholder="blurred"
                  width={80}
                  height={80}
                />
              </div>
              <div className={styles.twinWorkSec_unqTwnTreatmnt_contntPrt}>
                <h3>Body sensors</h3>
                <p>
                  High quality, medically safe sensors made in the USA & Germany
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center pt-4">
              <div className="align-self-start">
                <StaticImage
                  src="../../../images/appDx/mobile_app.png"
                  alt=""
                  placeholder="blurred"
                  width={100}
                  height={100}
                />
              </div>
              <div className={styles.twinWorkSec_unqTwnTreatmnt_contntPrt}>
                <h3>App that track & guide to improve your health</h3>
                <p>
                  Exclusive access to track your daily health progress, to be in
                  touch with your care team, etc
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-content-center fixed-bottom">
        <div className={styles.outerCon}>
          <div className={styles.btnCo}>
            <div
              role="presentation"
              onClick={() => {
                goToTwinPlan();
              }}
              className={styles.textCc}
            >
              Start your diabetes reversal journey
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwinWorksPage;
